import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { IoReorderThree } from "react-icons/io5";
import { Link as ScrollLink } from "react-scroll";
import logo from "../assets/svs.png";
import phone from "../assets/phone.svg";

export default function NavBar() {
  const [expanded, setExpanded] = useState(false);

  const handleToggleClick = () => {
    setExpanded(!expanded);
  };

  const handleNavLinkClick = () => {
    setExpanded(false); // Collapse the menu
  };

  // Determine the offset based on viewport width
  const getOffset = () => {
    if (window.innerWidth < 576) {
      return -230; 
    }
    return -70;
  };

  return (
    <Navbar expanded={expanded} expand="lg" className="navbar_main_div py-1">
      <Navbar.Brand>
        <ScrollLink to="home" offset={getOffset()} onClick={handleNavLinkClick}>
          <img src={logo} alt="logo" style={{ cursor: 'pointer', width: "5.34844rem", height: "auto" }} />
        </ScrollLink>
      </Navbar.Brand>
      <Navbar.Toggle 
        aria-controls="basic-navbar-nav"
        onClick={handleToggleClick}
      >
        <IoReorderThree style={{ fontSize: "1.5rem" }} />
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto text-center list__">
          <Nav.Link as={ScrollLink} to="home" offset={getOffset()} onClick={handleNavLinkClick}>Home</Nav.Link>
          <Nav.Link as={ScrollLink} to="about" offset={getOffset()} onClick={handleNavLinkClick}>About Us</Nav.Link>
          <Nav.Link as={ScrollLink} to="ourservices" offset={getOffset()} onClick={handleNavLinkClick}>Our Services</Nav.Link>
          <Nav.Link as={ScrollLink} to="location" offset={getOffset()} onClick={handleNavLinkClick}>Location</Nav.Link>
          <Nav.Link as={ScrollLink} to="contact" offset={getOffset()} onClick={handleNavLinkClick}>Contact Us</Nav.Link>
        </Nav>
        {/* <div className="d-flex align-items-center nav_btn mx-4 mx-md-0 mt-4 mt-md-0 py-3">
          <a href="tel:+01179665865" className="d-flex align-items-center" target="_blank" rel="noopener noreferrer">
            <img src={phone} alt="phone" />
            <p className="ms-2 mb-0">0117 966 5865</p>
          </a>
        </div> */}
      </Navbar.Collapse>
    </Navbar>
  );
}
