import React, { useState } from "react";
import { MdOutlineMailOutline } from "react-icons/md";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";

import ReCAPTCHA from "react-google-recaptcha";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captchaToken) {
      alert("Please verify that you are not a robot.");
      return;
    }
    // Send formData and captchaToken to your backend for verification
    console.log("Form data submitted:", formData);
    console.log("Captcha token:", captchaToken);
  };
  return (
    <div className="d-block d-md-flex contact_form_div" id="contact">
      <div className="col-12 col-md-5 contact_form_icons_div ">
      <h5 className="py-2 py-md-4">Contact Us</h5>
       
        <div className="d-flex align-items-center py-3">
          <div className="contact_icon_div me-3">
            <HiOutlineLocationMarker className="icon___" />
          </div>

          <div className="content_form">
            <h1>Address</h1>
            <h2>
            2 Gwilliam St, Bristol BS3 4LS
            </h2>
          </div>
        </div>

        <div className="d-flex align-items-center  py-3">
           
           <div className="contact_icon_div me-3">
             <MdOutlineMailOutline className="icon___" />
           </div>
 
           <div className="content_form">
             <h1>Email</h1>
             <h2>contact@svsminimarket-com</h2>
           </div>
         </div>

         {/* <div className="d-flex align-items-center  py-3">
           
           <div className="contact_icon_div me-3">
             <FiPhoneCall className="icon___" />
           </div>
 
           <div className="content_form">
             <h1>Phone</h1>
             <h2>0117 966 5865</h2>
             
           </div>
         </div> */}
      </div>

      <div className="col-12 col-md-7 contact_form_manin_div__">
        <form onSubmit={handleSubmit}>
          <div className="d-block d-md-flex justify-content-between align-items-center">
            <div className="mb-3 custom_col">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3 custom_col ">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="subject" className="form-label">
              Subject
            </label>
            <input
              type="text"
              className="form-control"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="message" className="form-label">
              Message
            </label>
            <textarea
              className="form-control"
              id="message"
              name="message"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="d-block d-md-flex justify-content-between align-items-center">
            {/* <div className="mb-3">
              <ReCAPTCHA
                sitekey="6LccYzYqAAAAAMzfLzkwbX8fCq0RSsYGZoPxY2N4" // Replace with your actual site key
                onChange={handleCaptchaChange}
              />
            </div> */}

            <div className="pb-4">
              <button
                type="submit"
                className="btn col-12 "
                style={{ fontWeight: "600", backgroundColor: "#6A704C",color:"white"}}
              >
                Send Message
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
