import React from "react";


export default function Map() {
  return (
    <div className="map_div_main " id="location">
        <div className="d-block d-md-flex ">
        <div className="col-12 col-md-6 map_header ">
        <h1>Visit Us at <br className="d-none d-lg-block"></br>Our Location</h1>
        <p>Find us at our headquarters, conveniently located in Bristol. Drop by to discuss how we can support your business needs, or reach out to us online for a consultation.</p>
        <button className="btn_custom">Get Directions</button>
      </div>

      <div className="google_map_embed col-12 col-md-6" style={{height:"550px"}}>
        <iframe 
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2487.016858780985!2d-2.5964013235013397!3d51.43948427179795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48718e81fbd035f5%3A0x77e4d53485cb45e4!2s2%20Gwilliam%20St%2C%20Bristol%20BS3%204LS%2C%20UK!5e0!3m2!1sen!2slk!4v1725555190478!5m2!1sen!2slk"
          
          width="100%"
          height="100%"
          style={{ border: "0" }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          
        ></iframe>
      </div>
      </div>
    </div>
  );
}
