import React from "react";
import service1 from "../assets/service1.svg";
import service2 from "../assets/service2.svg";
import service3 from "../assets/service3.svg";
import service4 from "../assets/service4.svg";
import service5 from "../assets/service5.svg";
import service6 from "../assets/service6.svg";

export default function OurService() {
  const services = [
    { name: "Sales Analytics", image: service1 },
    { name: "Multi-store Management", image: service2 },
    { name: "Employee Management", image: service3 },
    { name: "Payments", image: service4 },
    { name: "Point of Sale", image: service5 },
    { name: "Inventory Management", image: service6 },
  ];

  return (
    <div className="service_main_div" id="ourservices">
      <div className="row">
        {/* Left column with title and description */}
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center ourservice_header_div__  py-4 py-md-0">
          <h1>Our Services</h1>
          <p className="col-12 col-md-9">
            Explore our range of services designed to optimize your business, including sales analytics, multi-store management, employee management, secure payments, point of sale systems, and inventory management.
          </p>
        </div>

        {/* Services grid */}
        <div className=" col-12 col-md-6">
          <div className="row px-2 px-md-0 pb-5" >
            {services.map((service, index) => (
             <div key={index} className="col-6 col-md-4 p-2">
             <div className="our_service_div p-3 text-center d-flex flex-column align-items-center justify-content-start pt-5">
               <img
                 src={service.image}
                 alt={service.name}
                 style={{ width: "2.5rem", height: "2.5rem", marginBottom: "0.5rem" }}
               />
               <h5 className="mt-2 text-white">{service.name}</h5>
             </div>
           </div>
           
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
