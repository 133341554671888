import React from "react";
import service1 from "../assets/service1.svg";
import service2 from "../assets/service2.svg";
import service3 from "../assets/service3.svg";
import service4 from "../assets/service4.svg";
import service5 from "../assets/service5.svg";
import service6 from "../assets/service6.svg";
import Carousel_02 from "../utility/Carousel_02";
import Carousel_01 from "../utility/Carousel_01";

export default function AboutUs() {


  return (
    <div id="about">
        <Carousel_02/>
        <Carousel_01/>

    </div>
  );
}
