import React from "react";

export default function FooterBotom() {
 
  return (
    <div className="footer_botom d-flex justify-content-center align-items-center my-0">
      <p className="mb-0" >Copyright © 2024 SAT Traders. All rights reserved.</p>
    </div>
  );
}
