import React from "react";
import NavBar from "../pages/NavBar";

export default function HomeHeader() {
  return (
    <div className="sticky">
    <NavBar/>
  </div>
  );
}
