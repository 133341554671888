import React from "react";
import logo from "../assets/svs.png"
import { MdArrowOutward } from "react-icons/md";
import { Link as ScrollLink } from "react-scroll";



export default function Footer() {
 
  return (
    <div className=" footer_main_div d-block d-md-flex justify-content-between align-items-center">
      <div className=" footer_1">
        <ScrollLink to="home" offset={-100}><img src={logo} style={{cursor:'pointer',height:"6.35244rem",width:"10.16244rem"}}></img></ScrollLink>
        <h3 className="pt-3">SVS Mini Market</h3>
        <h4 className="col-12">2 Gwilliam St, Bristol BS3 4LS</h4>
        
       
      </div>

      <div className=" justify-content-center align-items-center footer_2" >
        <ul className="d-block d-md-flex ps-0 py-4 py-md-0">
        <li><ScrollLink to="home" offset={-100}>Home</ScrollLink></li>
                <li><ScrollLink to="about" offset={-100}>About Us</ScrollLink></li>
                <li><ScrollLink to="ourservices" offset={-100}>Our Services</ScrollLink></li>
                <li><ScrollLink to="location" offset={-100}>Location</ScrollLink></li>
                <li><ScrollLink to="contact" offset={-100}>Contact Us</ScrollLink></li>
        </ul>
      </div>

      <div className="get_main_div">
        <a href="https://maps.app.goo.gl/j1Gj8UBGQrozh9H76" className="d-flex justify-content-center align-items-center"  target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>
        <div className="d-flex justify-content-center align-items-center footer_3 py-1 py-md-4">
          
        <div className="get_bg "><h1 className="text-end">Get</h1></div>
        <h2 className="mb-0">Directions</h2>
        <MdArrowOutward className="get_icon"/>
        </div>
        </a>
      </div>
    </div>
  );
}
