import React from "react";
import HomeCarousel_1 from "../utility/HomeCarousel_1";
import check from "../assets/check.svg"





export default function Home() {
  return (
    <div className="home_main_div" id="home">
<div className="d-block d-md-flex">
    <div className="col-12 col-md-7 home_header">
        <h1 className="col-12 col-md-10">Build the best tech for your business</h1>
        <p className="col-12 col-md-8 py-3">Optimise your retail operations with our easy to use software platform.</p>
        <div className="home_list">
            <ul>
                <li><img src={check}></img>Sales Analytics</li>
                <li><img src={check}></img>Multi-store Management</li>
                <li><img src={check}></img>Employee Management</li>
                <li><img src={check}></img>Payments</li>
                <li><img src={check}></img>Point of Sale</li>
                <li><img src={check}></img>Inventory Management</li>
               
            </ul>
        </div>
    </div>

    <div className="col-12 col-md-5 d-flex justify-content-end align-items-end" style={{ width: '41.67%%', height: '100%' }}> 
        <HomeCarousel_1/>
    </div>
</div>
  </div>
  );
}
