import React from 'react';
import image from "../assets/car_3.png";
import car2 from "../assets/car_2.png";
import car3 from "../assets/car4.png";

const images = [
  { src: image, alt: 'Analytics Dashboard' },
  { src: car2, alt: 'POS Terminal' },
  { src: car3, alt: 'Business Use Case' },
  { src: image, alt: 'Analytics Dashboard' },
  { src: car2, alt: 'POS Terminal' },
  { src: car3, alt: 'Analytics Dashboard' },
  { src: image, alt: 'Analytics Dashboard' },
  { src: car2, alt: 'POS Terminal' },
  { src: car3, alt: 'Analytics Dashboard' },
  
  
];

const Carousel_01 = () => {
  return (
    <div>
      <div className='d-none d-md-block'>
    <div className="abc__container d-block d-md-flex">
      <div className="abc__carousel d-flex">
        <div className="abc__carousel-inner">
          {images.map((item, index) => (
            <div key={index} className="abc__carousel-item">
              <img src={item.src} alt={item.alt} />
            </div>
          ))}
        </div>
      </div>
      <div className="abc__text-section">
        <h2>A smart solution tailor-made for your business</h2>
        <p>
        Every business is different. To achieve your goals, you’ll need the right POS software that fits your needs and adjusts to any changes. Select your business type below to learn more about how POS can help you:
        </p>
      </div>
    </div>
    </div>

    <div className='d-block d-md-none'>
    <div className="abc__container d-block d-md-flex">
      
      <div className="abc__text-section">
        <h2>A smart solution tailor-made for your business</h2>
        <p>
        Every business is different. To achieve your goals, you’ll need the right POS software that fits your needs and adjusts to any changes. Select your business type below to learn more about how POS can help you.
        </p>
      </div>
      <div className="abc__carousel d-flex">
        <div className="abc__carousel-inner">
          {images.map((item, index) => (
            <div key={index} className="abc__carousel-item">
              <img src={item.src} alt={item.alt} />
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default Carousel_01;
