import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Map from './pages/Map';
import Contact from './pages/Contact';
import Footer from './pages/Footer';
import FooterBotom from './pages/FooterBotom';
import OurService from './pages/OurService';
import HomeCarousel from './utility/HomeCarousel_1';
import Home from './pages/Home';
import NavBar from './pages/NavBar';
import HomeHeader from './compoents/HomeHeader';
import Carousel_01 from './utility/Carousel_01';
import Carousel_02 from './utility/Carousel_02';
import AboutUs from './pages/AboutUs';

function App() {
  return (
    <div>
      <HomeHeader/>
      <Home/>
      <AboutUs/>
      <OurService/>
      <Map/>
      <Contact/>
      <Footer/>
      <FooterBotom/>
      
      

    </div>
  );
}

export default App;
