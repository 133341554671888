import React from "react";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import home_carosuel_1 from "../assets/home.png"



export default function HomeCarousel_1() {
  return (
    <div className="carousel-container">
    <Carousel
      showArrows={false}          
      autoPlay={true}             
      infiniteLoop={true}         
      showThumbs={false}          
      showStatus={false}          
      interval={2000}          
    >
      <div>
        <img src={home_carosuel_1} alt="Slide 1" />
      </div>
      <div>
        <img src={home_carosuel_1} alt="Slide 2" />
      </div>
      <div>
        <img src={home_carosuel_1} alt="Slide 3" />
      </div>
      
    </Carousel>
  </div>
  );
}

