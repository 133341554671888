import React from 'react';
import image from "../assets/car_01_1.png";
import car2 from "../assets/car_01_2.png";
import car3 from "../assets/car_01_3.png";

const images = [
  { src: image, alt: 'Analytics Dashboard' },
  { src: car2, alt: 'POS Terminal' },
  { src: car3, alt: 'Business Use Case' },
  { src: image, alt: 'Analytics Dashboard' },
  { src: car2, alt: 'POS Terminal' },
  { src: car3, alt: 'Analytics Dashboard' },
  { src: image, alt: 'Analytics Dashboard' },
  { src: car2, alt: 'POS Terminal' },
  { src: car3, alt: 'Analytics Dashboard' },
  
  
];

const Carousel_02 = () => {
  return (
    <div className="abc__02__container d-block d-md-flex ">
      
      <div className="abc__02__text-section py-4 py-md-0">
        <h2>Technology service provider for retail stores</h2>
        <p>
        Turn your smartphone or tablet into a powerful POS. Manage sales, inventory and employees with ease; engage customers and increase your revenue. Whether you have single or multiple stores, our tools help run your business.
        </p>
      </div>

      <div className="abc__02__carousel d-flex">
        <div className="abc__02__carousel-inner">
          {images.map((item, index) => (
            <div key={index} className="abc__02__carousel-item">
              <img src={item.src} alt={item.alt} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel_02;
